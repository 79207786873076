.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.lightContainer {
  display: flex;
  align-items: center;
  overflow: scroll;
  width: 100vw;
  height: 100vh;
  background-image: url("./images/Rectangle 4 (1).png");
  /* backdrop-filter: ; */
  background-size: cover;
}

.darkContainer {
  display: flex;
  overflow: scroll;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-image: url("./images/Rectangle 4 (2).png");
  background-size: cover;
}

.col {
  width: 50%;
  margin-block: 10px;
}

.center {
  text-align: center;
}

.mt100 {
  margin-top: 100px;
}

.mtc {
  margin-top: 25vh;
}

.flex {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.header1 {
  color: white;
  font-size: larger;
  font-weight: 500;
}

.header2 {
  color: white;
  font-size: medium;
  font-weight: 400;

}

.cusbutton {
  width: 75%;
  padding: 15px;
  border-radius: 50px;
  border: none;
  color: white;
  background-color: #CC365E;
  margin-block: 20px;

}

.cusinput {
  width: 75%;
  border: none;
  padding: 15px;
  border-radius: 50px;
  filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.3));
  margin-block: 15px;
}

.inputlable {
  color: white;
  font-size: small;
  font-weight: 500;
  align-self: flex-end;
  margin-inline-end: 10%;
}

@media only screen and (max-width: 768px) {
  .lightContainer {
    background-image: url("./images/Rectangle 4.png") !important;
    flex-direction: column-reverse;
    justify-content: space-around;
  }

  .darkContainer {
    background-image: url("./images/Rectangle 5.png") !important;
    flex-direction: column-reverse;
    justify-content: space-around;

  }

  .col {
    width: 100%;
  }

}